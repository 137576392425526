import Vue from 'vue';

import { Label } from '../../../core/types/i18n';
import { Response } from './../../../core/types/http';
import { AttributeTypes, DisplayMode } from './../constants/index';

export namespace AttributeValues {
  export interface ExternalLinkValueItemResource {
    url?: string;
    path?: string;
    storage?: string;
  }
  export interface ExternalLinkValueItem {
    id: string;
    name: string;
    externalId?: any;
    position: number;
    resource: ExternalLinkValueItemResource;
    thumbnail: ExternalLinkValueItemResource;
    preview: ExternalLinkValueItemResource;
  }

  export interface ComboValueItem {
    data: {
      id_op: number;
      libelle_op: string;
      code_op: string;
      date_deb_op: string;
      date_fin_op: string;
    };
    label: Label;
    itemId: string;
    alias: string;
  }

  /**
   * Either the option item, or simply the alias of the option
   */
  export type ComboValue = (ComboValueItem | string)[];

  /**
   * Tables contain lines of columns, where each cell is a normal attribute value
   */
  export type TableValue = AttributeValue[][];

  /**
   * The possible scalar value types of an attribute
   */
  export type ScalarValue = boolean | number | string | null;

  /**
   * The possible compound value types of an attribute
   */
  export type CompoundValue = ComboValue | TableValue | ExternalLinkValueItem[];

  /**
   * All the value types of an attribute
   */
  export type AttributeValue = ScalarValue | CompoundValue;

  export interface ValueObject {
    attributeAlias: string;
    attributeId: string | null;
    value: AttributeValue;
    type: AttributeTypes;
  }

  /**
   * Option value object for an attribute
   * Ex: Links options
   */
  export type AttributeOptionValue = {
    label: Label;
    itemId: string;
    alias: string;
    children?: AttributeOptionValue[];
  };
}

/**
 * Form dtos
 */
export namespace Form {
  /**
   * Parameter link
   */
  export interface Link {
    attribute: string;
    values: any[];
    label: Label;
    createdDate?: any;
    updatesDate?: any;
    paths?: any;
    itemId: string;
    alias: string;
  }

  /**
   * Parmeters
   */
  export interface Parameters {
    links: Link[];
  }

  /**
   * Search link
   */
  export interface SearchLink {
    picto: string;
  }

  /**
   * Attribute table column
   */
  export interface AttributeTableColumns extends Table.Column {
    scopes: string[];
    tags: string[];
    attribute: Attribute;
  }

  /**
   * Display options
   */
  export interface DisplayOptions {
    placeholder?: null | string;
    showImage: boolean;
    itemLabel?: null | any;
    valueField?: null | any;
    rowCount?: null | any;
    height?: any;
    orientation: string;
    width?: any;
    tableOptions?: { columns: AttributeTableColumns[] };
  }

  /**
   * Options
   */
  export interface Options {
    showTime?: boolean;
    displayMode?: DisplayMode;
    displayOptions?: DisplayOptions;
    enabled: boolean;
    format?: string | null;
    itemLabel?: null | string;
    /**
     * Maximum number (included)
     */
    maximum?: number | null;
    /**
     * Maximum number of items in the array (included)
     */
    maximumItems?: number | null;
    /**
     * Maximum string length (included)
     */
    maximumLength?: number;
    maximumMessage?: null | string;
    /**
     * Minimum number  (included)
     */
    minimum?: number;
    /**
     * Minimum number of items in the array (included)
     */
    minimumItems?: number | null;
    /**
     * Minimum string length (included)
     */
    minimumLength?: number;
    multiple?: boolean;
    multiSelect?: boolean;
    noOptionsMessage?: null | string;
    placeholder?: null;
    precisionDigit?: number;
    /**
     * If a value is required
     */
    required: boolean;
    rowCount?: number;
    searchLink: SearchLink | null;
    showCharacterCounter?: boolean;
    showLabel: boolean;
    showNoOptions?: boolean;
    step?: number;
    type: AttributeTypes;
    valueField?: null | string;
    valueImportConfiguration?: any;
    visible: boolean;
  }

  /**
   * Attribute group
   */
  export interface Group {
    itemId: string;
    position: number;
  }

  /**
   * Attribute source
   */
  export interface Source {
    sourceId: string;
    provider: string;
    parameters: object;
  }

  /**
   * Attribute
   */
  export interface Attribute {
    scopes: string[];
    tags: string[];
    label: Label;
    type: AttributeTypes;
    defaultValue: any;
    parameters: Parameters | null;
    options: Options;
    groups: Group[];
    tabs: any | null;
    updateOn: string[] | null;
    search: unknown;
    source: Source | null;
    links: any | null;
    permission: any | null;
    isLink: boolean;
    isScalar: boolean;
    hasDefaultValue: boolean;
    createdDate: string;
    updatesDate: string;
    paths: string[];
    itemId: string;
    alias: string;
  }

  /**
   * Attribute group
   */
  export interface AttributeGroup {
    label: Label;
    attributes: Attribute[];
    isOpenByDefault: boolean;
    showTitle: boolean;
    hasChildren: boolean;
    hasItems: boolean;
    isRoot: boolean;
    depth: number;
    parent: string;
    position: number;
    permission: string | null;
    createdDate: string;
    updatesDate: string;
    paths: string[];
    itemId: string;
    alias: string;
  }

  /**
   * Table
   */
  export namespace Table {
    export interface Column {
      name: Label;
      /**
       * Css width (ex: '20%')
       */
      width: string;
      alignment: null | string;
      visible: boolean;
      sortable: boolean;
      editable: boolean;
      editor: Editor | null;
      formatter: Formatter | null;
      renderer: string | null;
      permission: string | null;
      minimizedWidth?: any;
      alias: string;
      label: Label;
    }

    /**
     * Editor
     */
    export interface Editor {
      type: string;
    }

    /**
     * Formatter
     */
    export interface Formatter {
      alias: string;
      pattern: null;
    }
  }

  /**
   * Form
   */
  export interface Form {
    attributeGroups: AttributeGroup[];
    createdDate?: any;
    updatesDate?: any;
    paths?: any;
    itemId: string;
    alias?: any;
  }
}

const ATTRIBUTES_API_URL = '/api/piivo/v1/attributes';

export default {
  async importAttributeValue(
    attributeId: string,
    file: File,
    beforeRequestCb: ((request: unknown) => void) | null = null
  ): Response<AttributeValues.ValueObject> {
    const formData = new FormData();
    formData.append('myFile', file);

    return Vue.http.post(`${ATTRIBUTES_API_URL}/${attributeId}/values/import`, formData, {
      before(request: unknown) {
        if (beforeRequestCb) {
          beforeRequestCb(request);
        }
      },
    }) as Response<AttributeValues.ValueObject>;
  },

  /**
   * Get possible option values for an attribute
   *
   * @param attributeId - The template identifier.
   * @param bodyParameters - The optional body parameters.
   */
  async getPossibleValues<OptionValue extends AttributeValues.AttributeOptionValue>(
    attributeId: string,
    bodyParameters: Map<string, unknown>
  ): Response<OptionValue[]> {
    return Vue.http.post(
      `${ATTRIBUTES_API_URL}/${attributeId}/values`,
      Object.fromEntries(bodyParameters || [])
    ) as Response<OptionValue[]>;
  },
};
