import moment from 'moment';

import { Form } from '../api/attributes';
import { AttributeTypes, DisplayMode } from '../constants';
import { EditableTemplate } from './../../poster/types/templates';
import { iterateForm, parseNumericValue } from './formsHelper';

const DEFAULT_ERROR_MAP = {
  required: true,
  maximumLength: true,
};

/**
 * Object of options that did not pass validation
 */
export type Problem = {
  [option: string]: true;
};

/**
 * Contains sub objects for errors and warnings triggered by validation on
 * this attribute
 */
export interface AttributeValidationResult {
  /**
   * Error-class problems for the attribute
   */
  errors: Problem;
  /**
   * Error-class problems for the attribute's children (table),
   * for the children that have errors
   */
  childrenErrors: {
    [attributeId: string]: Problem;
  };
  /**
   * Warning-class problems for the attribute
   */
  warnings: Problem;
  /**
   * Warning-class problems for the attribute's children (table),
   * for the children that have warnings
   */
  childrenWarnings: {
    [attributeId: string]: Problem;
  };
}

/**
 * Form validation options
 */
export interface FormValidationOptions {
  /**
   * If attributes with options.visible: false should be validated
   */
  validateInvisibleItems: boolean;
  /**
   * If the function should return on the first error
   */
  breakOnFirstError: boolean;
}

/**
 * Contains sub objects for errors and warnings triggered by validation
 */
export interface ValidationResult {
  /**
   * Contains objects keyed by attribute name, with the keys of triggered errors,
   * for the attribute that have errors, or whose children have errors
   */
  errors: {
    [attributeId: string]: Pick<AttributeValidationResult, 'errors' | 'childrenErrors'>;
  };
  /**
   * Contains objects keyed by attribute name, with the keys of triggered warnings,
   * for the attribute that have errors, or whose children have errors
   */
  warnings: {
    [attributeId: string]: Pick<AttributeValidationResult, 'warnings' | 'childrenWarnings'>;
  };
}

/**
 * @param value - the string to test
 * @param trim - if should test against the trimmed value
 */
export function validateRequiredString(value: unknown, trim = false): boolean {
  return !!value && (!trim || !!(value as string).trim());
}

/**
 * @param value - the string to test
 * @param minLength - the minimum (included) length for the string
 * @param breakOnInvalidStringOrRequirement - return false if `value` or `minLength` are falsy
 * @returns if the length of the string is indeed greater than or equal to the min length
 */
export function validateStringMinLength(
  value: unknown,
  minLength: number,
  breakOnInvalidStringOrRequirement = false
): boolean {
  if (!breakOnInvalidStringOrRequirement && (value == null || minLength == null)) {
    // If we ignore the invalid requirement, we cannot compare the string length
    return true;
  }

  return value != null && (value as string).length >= minLength;
}

/**
 * @param value - the string to test
 * @param maxLength - the max (included) length for the string
 * @param breakOnInvalidStringOrRequirement - return false if `value` or `maxLength` are falsy
 * @returns if the length of the string is indeed less than or equal to the max length
 */
export function validateStringMaxLength(
  value: unknown,
  maxLength: number,
  breakOnInvalidStringOrRequirement = false
): boolean {
  // If we ignore the invalid requirement, we cannot compare the string length
  // Explicit rule: if maxLength is 0, consider as if no max length is defined.
  // Thus, the value will always respect the max length rule.
  if (
    !breakOnInvalidStringOrRequirement &&
    (value == null || maxLength == null || maxLength === 0)
  ) {
    return true;
  }

  return value != null && (value as string).length <= maxLength;
}

/**
 *
 * @param value - array of strings to test
 * @returns if the array is truthy, contains at least one string
 * and every item fulfills the 'required' string condition
 */
export function validateRequiredMultiString(value: unknown): boolean {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every((string) => validateRequiredString(string))
  );
}

/**
 *
 * @param value - array of string to test
 * @param minimumItems - the minimum amount of items (included) needed
 */
export function validateMinItems(value: unknown, minimumItems: number): boolean {
  if (!minimumItems || (typeof minimumItems === 'number' && minimumItems <= 0)) {
    // If the requirement is falsy, we can't use it to check the number of items
    // => accept the value
    return true;
  }
  if (minimumItems === 1 && typeof value === 'string' && !!value) {
    // If we just want to make sure there is a value, accept string value
    // as long as the value is truthy: sometimes Links values are just
    // the itemId/alias instead of the option object array
    return true;
  }

  // Else, minimumItems is a number greater than 1: a null value
  // cannot respect the min amount of items
  // => we must check that the value is an array

  return Array.isArray(value) && value.length >= minimumItems;
}

/**
 *
 * @param value - array of string to test
 * @param maximumItems - the maximum amount of items (included) needed
 */
export function validateMaxItems(value: unknown, maximumItems: number): boolean {
  if (!value || !maximumItems || (typeof maximumItems === 'number' && maximumItems < 0)) {
    // - If the value is falsy, it can never exceed the max number of items
    // => accept the value
    // - Ignore a requirement that is falsy or less than 0
    // => accept the value
    return true;
  }

  return Array.isArray(value) && value.length <= maximumItems;
}

/**
 * @param value - value to convert
 * @returns if the number could be converted and is not NaN
 */
export function validateRequiredNumeric(value: unknown): boolean {
  return (
    value !== undefined && value !== null && value !== '' && !Number.isNaN(parseNumericValue(value))
  );
}

/**
 * @param value - value to test
 * @param min - minimum value (included)
 * @param breakOnInvalidNumbers - return false if `value` or `min` are falsy
 */
export function validateMinNumeric(
  value: unknown,
  min: number,
  breakOnInvalidNumbers = false
): boolean {
  if (
    !breakOnInvalidNumbers &&
    (!validateRequiredNumeric(value) || !validateRequiredNumeric(min))
  ) {
    // If we ignore the invalid numbers, we cannot compare them, so return early
    return true;
  }

  const parsedValue = parseNumericValue(value);

  return validateRequiredNumeric(parsedValue) && min !== null && parsedValue >= min;
}

/**
 * @param value - value to test
 * @param max - maximum value (included)
 * @param breakOnInvalidNumbers - return false if `value` or `min` are falsy
 */
export function validateMaxNumeric(
  value: unknown,
  max: number,
  breakOnInvalidNumbers = false
): boolean {
  if (
    !breakOnInvalidNumbers &&
    (!validateRequiredNumeric(value) || !validateRequiredNumeric(max))
  ) {
    // If we ignore the invalid numbers, we cannot compare them, so return early
    return true;
  }

  const parsedValue = parseNumericValue(value);

  return validateRequiredNumeric(parsedValue) && max !== null && parsedValue <= max;
}

/**
 * @param value - value to test
 * @returns if the value could be parsed to a date
 */
export function validateRequiredDate(value: unknown): boolean {
  return moment(value as moment.MomentInput).isValid();
}

/**
 * @param value - value to test
 * @returns if the value is not null
 */
export function validateBoolean(value: unknown): boolean {
  return value != null;
}

/**
 * @param attribute - attribute
 * @param opts - validation options
 * @param opts.validateInvisibleItems - if should validate invisible attributes
 * @param opts.breakOnFirstError - if should return as soon as an error is found
 *
 * @returns the errors and warnings triggered by validating the attributes
 */
function ignoreAttributeValidation(
  attribute: Form.Attribute,
  opts = {
    validateInvisibleItems: false,
    breakOnFirstError: false,
  }
): boolean {
  return (
    !attribute || !attribute.options || (!opts.validateInvisibleItems && !attribute.options.visible)
  );
}

/**
 * @param value - value to test
 * @param attribute - form attribute for the value
 * @param opts - validation options
 * @param opts.validateInvisibleItems - if should validate invisible attributes
 * @param opts.breakOnFirstError - if should return as soon as an error is found
 *
 * @returns  object containing error & warning objects with triggered keys
 */
export function validateFormValue(
  value: unknown,
  attribute: Pick<Form.Attribute, 'type' | 'options'>,
  opts = {
    validateInvisibleItems: false,
    breakOnFirstError: false,
  }
): AttributeValidationResult {
  const checkResult: AttributeValidationResult = {
    errors: {},
    childrenErrors: {},
    warnings: {},
    childrenWarnings: {},
  };

  const attachProblem = (name: string) => {
    if (((attribute.options as any).errorMap || DEFAULT_ERROR_MAP)[name]) {
      checkResult.errors[name] = true;
    } else {
      checkResult.warnings[name] = true;
    }
  };

  // Dedicated case for multi string because we cannot compare in the switch
  if (attribute.type === AttributeTypes.STRING && attribute.options.multiple) {
    if (attribute.options.required && !validateRequiredMultiString(value)) {
      attachProblem('required');
    } else if (!validateMinItems(value, attribute.options.minimumItems!)) {
      // Function auto checks if 'min' exists
      attachProblem('minimumItems');
    } else if (!validateMaxItems(value, attribute.options.maximumItems!)) {
      // Function auto checks if 'min' exists
      attachProblem('maximumItems');
    }
    return checkResult;
  }

  switch (attribute.type) {
    case AttributeTypes.DATE:
      if (attribute.options.required && !validateRequiredDate(value)) {
        attachProblem('required');
      }
      break;
    case AttributeTypes.NUMERIC: {
      if (attribute.options.required && !validateRequiredNumeric(value)) {
        attachProblem('required');
      } else if (!validateMinNumeric(value, attribute.options.minimum!)) {
        // Function auto checks if 'min' exists
        attachProblem('minimum');
      } else if (!validateMaxNumeric(value, attribute.options.maximum!)) {
        // Function auto checks if 'min' exists
        attachProblem('maximum');
      }
      break;
    }
    case AttributeTypes.LINKS: {
      switch (attribute.options.displayMode) {
        case DisplayMode.Table: {
          const tableLines = (value as unknown[][]) || [];
          const columns = attribute.options.displayOptions!.tableOptions?.columns || [];

          for (let lineIndex = 0; lineIndex < tableLines.length; lineIndex++) {
            const line = tableLines[lineIndex];
            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
              const colValue = line[colIndex];
              const colAttribute = columns[colIndex].attribute;
              if (!colAttribute) {
                continue;
              }

              if (ignoreAttributeValidation(colAttribute, opts)) {
                continue;
              }

              const colValueCheckResult = validateFormValue(colValue, colAttribute, opts);
              if (Object.keys(colValueCheckResult.errors).length) {
                checkResult.childrenErrors[`[${lineIndex}][${colIndex}|${colAttribute.alias}]`] =
                  colValueCheckResult.errors;
              }
              if (Object.keys(colValueCheckResult.warnings).length) {
                checkResult.childrenWarnings[`[${lineIndex}][${colIndex}|${colAttribute.alias}]`] =
                  colValueCheckResult.warnings;
              }

              if (opts.breakOnFirstError && Object.keys(checkResult.errors).length > 0) {
                return checkResult;
              }
            }
          }
          break;
        }
        case DisplayMode.Tiles:
          break;
        case DisplayMode.Combo:
        case DisplayMode.Tree:
          if (attribute.options.required && !validateMinItems(value, 1)) {
            attachProblem('required');
          }
          break;
      }
      break;
    }
    case AttributeTypes.EXTERNAL_LINK:
      if (!validateMinItems(value, attribute.options.minimum!)) {
        // Specifically attach this problem as an error
        checkResult.errors.minimum = true;
      }
      if (!validateMaxItems(value, attribute.options.maximum!)) {
        // Specifically attach this problem as an error
        checkResult.errors.maximum = true;
      }
      if (attribute.options.required && !validateMinItems(value, 1)) {
        attachProblem('required');
      }
      break;
    case AttributeTypes.BOOLEAN:
      if (attribute.options.required && !validateBoolean(value)) {
        attachProblem('required');
      }
      break;
    case AttributeTypes.STRING:
    case AttributeTypes.LABEL:
      if (attribute.options.required && !validateRequiredString(value)) {
        attachProblem('required');
      } else if (!validateStringMinLength(value, attribute.options.minimumLength!)) {
        // Function auto checks if 'min' exists
        attachProblem('minimumLength');
      } else if (!validateStringMaxLength(value, attribute.options.maximumLength!)) {
        // Function auto checks if 'min' exists
        attachProblem('maximumLength');
      }
      break;
    default:
      break;
  }

  return checkResult;
}

const defaultOptions: FormValidationOptions = {
  validateInvisibleItems: false,
  breakOnFirstError: false,
};

/**
 * @param signageItemValues - object of values of the signage item
 * @param form - the form type for the signage item
 * @param opts - validation options
 * @param opts.validateInvisibleItems - if should validate invisible attributes
 * @param opts.breakOnFirstError - if should return as soon as an error is found
 * @param opts.attributeIdProp - the property of the attribute whose value
 *  will give a unique id, on which the attribute's value is indexed in the 'signageItemValues' map
 * @param opts.getAttributeValue - A function that receives the values
 * and the attribute whose value to return. By default, returns the value indexed under the
 * attribute's 'attributeIdProp' in 'signageItemValues'
 *
 * @returns the errors and warnings triggered by validating the attributes
 */
export function areAllAttributesValid(
  signageItemValues: EditableTemplate['bindings'],
  form: Form.Form,
  opts: Partial<FormValidationOptions> = defaultOptions
): ValidationResult {
  const problemsResult: ValidationResult = {
    errors: {},
    warnings: {},
  };

  if (!signageItemValues || !form) {
    return problemsResult;
  }

  const mergedOpts = {
    ...defaultOptions,
    ...opts,
  };

  iterateForm(form, (attribute) => {
    const nbErrors = Object.keys(problemsResult.errors).length;
    const attributeUniqueId = attribute.alias;
    if (
      ignoreAttributeValidation(attribute, mergedOpts) ||
      !Object.hasOwnProperty.call(signageItemValues, attributeUniqueId) ||
      (mergedOpts.breakOnFirstError && nbErrors > 0)
    ) {
      return;
    }

    const value = signageItemValues[attributeUniqueId];
    const attributeValidationResult = validateFormValue(value, attribute, mergedOpts);
    if (
      Object.keys(attributeValidationResult.errors).length > 0 ||
      Object.keys(attributeValidationResult.childrenErrors).length > 0
    ) {
      problemsResult.errors[attributeUniqueId] = {
        errors: attributeValidationResult.errors,
        childrenErrors: attributeValidationResult.childrenErrors,
      };
    }
    if (
      Object.keys(attributeValidationResult.warnings).length > 0 ||
      Object.keys(attributeValidationResult.childrenWarnings).length > 0
    ) {
      problemsResult.warnings[attributeUniqueId] = {
        warnings: attributeValidationResult.warnings,
        childrenWarnings: attributeValidationResult.childrenWarnings,
      };
    }
  });

  return problemsResult;
}
