export default {
  props: {
    /**
     * Data of the notification
     *
     * @type {object}
     * @default {}
     */
    data: {
      type: Object,
      default: () => ({}),
    },
    /**
     * The id of the notification
     *
     * @type {Number}
     */
    id: {
      type: String,
      required: true,
    },
  },
};
